import React, { useState, useEffect, useCallback, Fragment } from "react";
//import { createStore, useStore } from 'react-hookstore';
import { useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'
import { Redirect } from "react-router-dom"

//import { useMount  } from "react-use";
//import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
// Por ahora no capturamos forma de Pago. //
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { enviaEmailPeticion } from './../../apis/api-email.js'
import { listaPueblos } from './../../apis/api-pueblo.js'
import { listUserUsuario, listUserNif, crearCliente, updateCliente } from './../../apis/api-user.js'
import auth from './../../apis/auth-helper.js'

import ThemeGeroa from '../../components/ThemeGeroa.js'
import BotonValidacion from '../../components/botones/BotonValidacion'

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {},
    registro: {
        //border:'1px solid green',
        marginTop: '0px',
        minHeight: '65vh',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'center'
        alignItems: 'center'
    },
    paper: {
        //padding: theme.spacing(2),
        //margin: 'auto',
        //maxWidth: 500,
    },
    paper1: {
        //padding: theme.spacing(2),
        //margin: 'auto',
        //maxWidth: 500,
        width: '100%',
        maxWidth: 1080,
        display: 'flex',
        flexDirection: 'row',
        //justifyContent: 'center'
        alignItems: 'flex-start'
    },
    mensajeHelper: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-6px',
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },
    mensajeError: {
        color: '#ff3878',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-6px',
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },
    gridContainer: {
        //border:'1px solid orange',
        width: '100%',
        maxWidth: 1080,
        //display: 'flex',
        //justifyContent:"space-between",
        //justifyContent:"left",
    },
    itemText: {
        //border:'1px solid red',
        marginBottom: 10,
        marginRight: 10,
        minWidth: 346
    },
    textField: {
        marginBottom: 12,
        //marginRight: 25,
        minWidth: 345
    },
    itemDir: {
        //border:'1px solid red',
        marginBottom: 10,
        marginRight: 10,
        //minWidth: 346
    },
    textDir: {
        marginBottom: 12,
        //minWidth: 345
    },
    /*
    checkGroup: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    */
    itemCheck: {
        //border:'1px solid red',
        marginBottom: 10,
        marginRight: 10,
        minWidth: 346
    },
    itemBoton: {
        //border:'1px solid red',
        marginTop: 10,
        marginRight: 10,
        width: '100%',
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: 'flex-end'
    },
    iconChkbox: {
        //borderRadius: 3,
        width: 12,
        height: 12,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
            backgroundColor: '#106ba3', // verdecillo
        },
    },
    checkedIconChkBox: {
        backgroundColor: '#137cbd', // azul , '#487e4c', // verde
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 12,
            height: 12,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3', // verdecillo
        },
    },
    /* /// Si permitimos seleccionar la Forma de Pago. ///
    radio: {
        marginLeft: '30px',
        padding: '4px 4px 4px 4px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 12,
        height: 12,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#487e4c',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 12,
            height: 12,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    */

}));

/*  /// Para seleccionar la forma de Pago. ///   
function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.radio}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}
*/

const Usuarios = ({ t }) => {
    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');
    const classes = useStyles();

    // Usuario Identificado. //
    const usuIden = (valuesGlobales.emailUsuario !== '')

    const [values, setValues] = useState({
        nombreMercado: '',
        formasDePago: [],
        CliMer: '',
        CliUsu: '',
        verClave: false,
        CliEma: '',
        CliPas: '',
        CliNom: '',
        CliNif: '',
        CliTel: '',
        direc: [{
            DirNum: '001',
            DirNom: '',
            DirCal: '',
            DirCpo: '',
            DirPob: '',
            DirPro: '',
            DirPai: '',
            DirTel: '',
            DirDef: 1,
            DirMod: 1
        }],
        buscaNif: false,
        yaExiste: false,
        saltoIde: false
    });
    const [chkValues, setChkValues] = useState([
        { label: t('Consentimiento1'), checked: true },
        { label: t('Consentimiento2'), checked: true },
        { label: t('Consentimiento3'), checked: false }
    ]);
    const [errors, setErrors] = useState({});

    //function checkNIF(nif) {
    const checkNIF = useCallback((nif) => {
        let num, sum, c
        nif = nif.toUpperCase().replace(/[_\W\s]+/g, '');
        if (/^(\d|[XYZ])\d{7}[A-Z]$/.test(nif)) {
            num = nif.match(/\d+/);
            num = (nif[0] !== 'Z' ? (nif[0] !== 'Y' ? 0 : 1) : 2) + num;
            if (nif[8] === 'TRWAGMYFPDXBNJZSQVHLCKE'[num % 23]) {
                //return /^\d/.test(nif)? 'DNI': 'NIE';
                return true;
            }
        }
        else if (/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[\dA-J]$/.test(nif)) {
            sum = 0;
            for (let i = 1; i < 8; ++i) {
                num = nif[i] << i % 2;
                sum += Math.floor(num / 10) + num % 10
            }
            c = '' + (10 - sum % 10) % 10;
            if (((/[KLMNPQRSW]/.test(nif[0]) || (nif[1] + nif[2]) === '00') && nif[8] === 'JABCDEFGHI'[c]) ||
                (/[ABEH]/.test(nif[0]) && nif[8] === c) ||
                (/[CDFGJUV]/.test(nif[0]) && (nif[8] === 'JABCDEFGHI'[c] || nif[8] === c))) {
                //return /^[KLM]/.test(nif)? 'ESP': 'CIF';
                return true;
            }
        }
        return false;
    }, [])



    // Solicitud alta en Mercado. //
    useEffect(() => {
        // Si hay solicitud. //
        if (valuesGlobales.usuarioAltaMercado !== '') {
            // Solicitud Mercado. //
            //let mercadoAlta = valuesGlobales.usuarioAltaMercado.substr(0, 3)
            //let nombreMercado = valuesGlobales.usuarioAltaMercado.substr(3)
            let mercadoAlta = valuesGlobales.usuarioAltaMercado.merCod
            let nombreMercado = valuesGlobales.usuarioAltaMercado.merNom
            let cooEma = valuesGlobales.usuarioAltaMercado.cooMail
            // Vaciar el mercado ya que ya se ha usado
            valuesGlobales.usuarioAltaMercado = ''
            // Esto no lo actualiza sincronamente. //
            //setValuesGlobales({ ...valuesGlobales, usuarioAltaMercado: '' })

            // Coger las formas de Pago del Mercado de la Solicitud. //
            let fpm = valuesGlobales.mercados.find(m => m.name === mercadoAlta).formasDePago
            // Coger la primera forma de pago. //
            let fp0 = fpm[0].tipo

            setValues(values => ({
                ...values,
                nombreMercado: nombreMercado,
                CliMer: mercadoAlta,
                cooEma: cooEma,
                formasDePago: fpm,
                formaDePago: fp0 // Por ahora no se selecciona
            }))
        }
    }, [valuesGlobales.usuarioAltaMercado, valuesGlobales, setValuesGlobales]);


    useEffect(() => {
        // Para no definir una función, la creamos y la llamamos. //
        (async () => {
            // Si no se han cargado las poblaciones. //
            if (valuesGlobales.poblaciones.length < 1) {
                try {
                    let dataP = await listaPueblos()
                    // Actualizar poblaciones. //
                    valuesGlobales.poblaciones = dataP
                    // Esto no lo actualiza sincronamente. //
                    //setValuesGlobales((valuesGloblaes) => ({ ...valuesGlobales, poblaciones: dataP }))
                } catch (error) {
                    console.log("Error carga poblaciones: " + error)
                }
            }
        })()
    }, [valuesGlobales.poblaciones, valuesGlobales, setValuesGlobales]);


    useEffect(() => {
        // Para no definir una función, la creamos y la llamamos. //
        (async () => {
            // Si el Usuario está Identificado. //
            if (valuesGlobales.emailUsuario !== '') {
                //console.log('values globales = ', valuesGlobales)
                //const values1 = {...values}
                const values1 = {}
                //const newValues = [...chkValues];
                const newValues = []
                // Busca datos del usuario actual. //
                try {
                    const dataU = await listUserUsuario({ usuario: valuesGlobales.usuario })
                    if (dataU !== undefined) {
                        let data = dataU[0]
                        //
                        values1.CliUsu = data.CliUsu
                        values1.CliEma = data.CliEma
                        values1.CliPas = data.CliPas || "No modificable"
                        values1.CliNom = data.CliNom
                        values1.CliNif = data.CliNif
                        values1.CliTel = data.CliTel
                        // direcciones. //
                        values1.direc = data.direc
                        // Consentimientos. //
                        for (let i = 1; i < 4; ++i) {
                            //const value = chkValues[i];
                            //newValues[i] = {...value, checked: data["consentimiento"+ (i+1)]};
                            newValues.push({
                                label: t("Consentimiento" + i),
                                checked: data["CliCo" + i] === 1
                            })
                        }
                    }
                } catch (error) {
                    console.log("Error usuario: " + valuesGlobales.emailUsuario + " " + error)
                }
                //console.log('values1 = ', values1)
                // Actualiza valores. //
                setValues(values => ({ ...values, ...values1 }))
                setChkValues(newValues)
            }
        }
        )()
    }, [valuesGlobales.emailUsuario, valuesGlobales.usuario, t]);


    // Ver si hay ya un usuario con el mismo NIF. //
    useEffect(() => {
        // Llamar sólo si el usuario no está identificado. //
        if (!usuIden && values.buscaNif) {
            // Para no definir una función, la creamos y la llamamos. //
            (async () => {
                //console.log("buscaNif:", values.CliNif)        
                const CliNif = values.CliNif
                const buscar = CliNif.length === 9 && checkNIF(CliNif)
                // Si hay que Buscar. //
                if (buscar) {
                    let yaExiste = false
                    let saltoIde = false
                    // Busca CliNom del NIF actual. //
                    try {
                        const dataU = await listUserNif({ CliNif })
                        if (dataU !== undefined) {
                            if (dataU.length > 0) {
                                if (window.confirm(dataU[0].CliNom + " " +
                                    t("ya esta registrado con ese DNI o NIF") +
                                    ".\n" +
                                    t("¿Desea identificarse?"))) {
                                    saltoIde = true
                                    yaExiste = true
                                } else {
                                    values.CliNif = ''
                                    yaExiste = false
                                }
                            }
                        }
                    } catch (error) {
                        console.log("Error listUserNIF." + error)
                    }
                    // No repetir la busqueda si no cambia el NIF. //
                    setValues(values => ({ ...values, buscaNif: false, yaExiste: yaExiste, saltoIde: saltoIde }))
                }
            }
            )()
        }
    }, [usuIden, values, values.buscaNif, values.CliNif, checkNIF, t]);

    let euskarriak = false
    console.log("Values globales: ", valuesGlobales)
    console.log("mercado alta en values globales: ", valuesGlobales.usuarioAltaMercado)
    if(valuesGlobales.intentoMercado === "021"){
        euskarriak = true
    }

    function localizaPoblacion(cp) {
        //console.log('vg poblaciones =', valuesGlobales.poblaciones)
        let poblacion = ""
        for (let index = 0; index < valuesGlobales.poblaciones.length; index++) {
            if ((cp >= valuesGlobales.poblaciones[index].codPostalD) && (cp <= valuesGlobales.poblaciones[index].codPostalH)) {
                poblacion = valuesGlobales.poblaciones[index].nombre
                return poblacion
            }
        }
        return poblacion
    }

    const handleChange = (name, index) => event => {
        let dato = event.target.value
        // -- Si es Teléfono sólo coger 13 caracteres. -- //
        if ((name === 'CliTel' || name === 'DirTel') && dato.length > 13) {
            dato = dato.slice(0, 13)
        }
        // Si ha cambiado el NIF. //
        if (name === 'CliNif') {
            // Buscar el NIF si tiene 9 caracteres. //
            values.buscaNif = (dato.length === 9 && checkNIF(dato))
            // Si habia marca de ya existente. //
            if (values.yaExiste) { values.yaExiste = false }
        }
        if (index === undefined) {
            setValues(values => ({ ...values, [name]: dato }));
        }
        else {
            let direc = [...values.direc]
            let dir = direc[index]
            if ((name === 'DirCpo') && (dato.length === 5)) {
                //console.log("Antes C.P.: ", event.target.value)
                // Si Codigo Postal completo - obtener la Localidad
                dir.DirPob = localizaPoblacion(dato)
                //console.log('cambiando localidad = ', dir.DirPob, index)
            }
            dir[name] = dato
            // Poner dato Direccion Modificada. //
            dir.DirMod = 1

            setValues(values => ({ ...values, direc: direc }));

        }
    }

    const onChangeChk = index => ({ target: { checked } }) => {
        const newValues = [...chkValues];
        const value = chkValues[index];
        newValues[index] = { ...value, checked };
        setChkValues(newValues);
    };

    const handleClickVerClave = () => {
        setValues(values => ({ ...values, verClave: !values.verClave }));
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    /*
    const changeFormaDePago = event => {
        event.preventDefault()
        setValues(values => ({ ...values, formaDePago: event.target.value }));
    }
    */



    // Valida únicamente la sintaxis de los 2 campos (email y password)
    const validate = (values) => {
        // vaciar errores. //
        errors.usuario = '';
        errors.email = '';
        errors.password = '';
        errors.name = '';
        errors.nif = '';
        errors.telefono = ''
        //
        //console.log('valuesV =', values)
        // usuario
        if (values.CliUsu.length < 4) {
            errors.usuario = t('El usuario debe tener al menos 4 caracteres')
        }
        // email
        if (values.CliEma.length === 0) {
            errors.email = t('La dirección de Email es obligatoria')
        } else if (!/\S+@\S+\.\S+/.test(values.CliEma)) {
            errors.email = t('La dirección de Email es inválida')
        }
        // password
        if (values.CliPas.length === 0) {
            errors.password = t('El password es obligatorio')
        } else if (values.CliPas.length < 4) {
            errors.password = t('Este password parece demasiado corto')
        }
        // nif
        if (values.yaExiste) {
            errors.nif = t('El dni o nif ya existe. Acceda por identificarse')
        } else {
            if (values.CliNif.length === 0) {
                errors.nif = t('El dni o nif es obligatorio')
            } else if (values.CliNif.length !== 9) {
                errors.nif = t('El dni o nif debe tener 9 caracteres')
            }
            else if (!checkNIF(values.CliNif)) {
                errors.nif = t("NIF erróneo");
            }
        }
        // name
        if (values.CliNom.length === 0) {
            errors.name = t('El nombre o razón social es obligatorio')
        } else if (values.CliNom.length < 6) {
            errors.name = t('El nombre debe tener al menos 6 caracteres')
        }
        // name
        if (values.CliTel.length === 0) {
            errors.telefono = t('El telefon es obligatorio')
        } else if (values.CliTel.length < 9) {
            errors.telefono = t('El teléfono debe tener al menos 9 caracteres')
        }
        // Direcciones. //
        errors.direc = values.direc.map(dir => {
            let nombre = ''
            let direccion = ''
            let poblacion = ''
            let codigoPostal = ''
            let provincia = ''
            let telefono = ''
            // nombre
            if (dir.DirNom.length === 0) {
                nombre = t('El nombre es obligatorio')
            }
            // direccion
            if (dir.DirCal.length === 0) {
                direccion = t('El domicilio es obligatorio')
            }
            // poblacion
            if (dir.DirPob.length === 0) {
                poblacion = t('La población es obligatoria')
            }
            // código Postal
            if (dir.DirCpo.length !== 5) {
                codigoPostal = t('El Código Postal es obligatorio')
            }
            // provincia
            if (dir.DirPro.length === 0) {
                provincia = t('La provincia es obligatoria')
            }
            // telefono
            if (dir.DirTel.length === 0) {
                telefono = t('El teléfono es obligatorio')
            }
            // si el dato del objeto tiene el mismo nombre no hace   //
            // falta poner: {dato: dato} poner {dato} es suficiente. //
            return { nombre, direccion, poblacion, codigoPostal, provincia, telefono }
        })
        //console.log('errors = ', errors)
        return errors
    }

    function sinError(errors) {
        let errdir = false
        for (let index = 0; index < errors.direc.length; index++) {
            if (errors.direc[index].nombre !== '' ||
                errors.direc[index].direccion !== '' ||
                errors.direc[index].poblacion !== '' ||
                errors.direc[index].codigoPostal !== '' ||
                errors.direc[index].provincia !== '' ||
                errors.direc[index].telefono !== '') {
                errdir = true
                break
            }
        }
        return (
            errors.usuario === '' &&
            errors.email === '' &&
            errors.password === '' &&
            errors.name === '' &&
            errors.nif === '' &&
            errors.telefono === '' &&
            !errdir
        )
    }

    async function handleSubmit(event) {
        //console.log('handle = ', values)
        event.preventDefault()
        // Valida que se haya rellenado el formulario
        setErrors(validate(values))
        // Para que actualize, llamamos al gancho
        setValues({
            ...values,
            usuario: values.CliUsu,
            email: values.CliEma,
            password: values.CliPas,
            name: values.CliNom,
            nif: values.CliNif.toUpperCase(),
            CliTel: values.CliTel,
        });
        ////         ///////////////////////////////////////// falta el valor DirNom que es el Nombre de la Direccion
        // Si No hay errores. //
        if (sinError(errors)) {
            try {
                let data, objUser
                let peticionOk = false
                // Si está Identificado. //
                if (usuIden) {
                    // Modificar Usuario. //
                    objUser = {
                        modificado: true,
                        usuario: values.CliUsu,
                        email: values.CliEma,
                        password: values.CliPas,
                        name: values.CliNom,
                        nif: values.CliNif.toUpperCase(),
                        CliTel: values.CliTel,
                        direc: values.direc,
                        consentimiento1: chkValues[0].checked,
                        consentimiento2: chkValues[1].checked,
                        consentimiento3: chkValues[2].checked,
                        // Solicitudes. //
                        CliMer: values.CliMer,
                        idioma: valuesGlobales.idioma
                    }

                    // Token. //
                    const jwt = auth.isAuthenticated()
                    // Modificar el Usuario. //
                    data = await updateCliente({ userId: jwt.user.codigo }, { t: jwt.token }, objUser);
                    // Procesar respuesta. //
                    if (data === undefined) {
                        throw t('Error al actualizar datos')
                    } else if (data.error) {
                        throw t(data.error)
                    }
                    else {
                        // Actualizar Values Globales. //
                        valuesGlobales.nombreUsuario = values.CliNom
                        valuesGlobales.emailUsuario = values.CliEma
                        valuesGlobales.nifUsuario = values.CliNif
                        valuesGlobales.dirUsuario = values.direc.map((d, i) => {
                            return {
                                ////////////nombre: i === 0 ? values.CliNom : d.DirCal,
                                nombre: d.DirNom,
                                numero: d.DirNum,
                                direccion: d.DirCal,
                                poblacion: d.DirPob,
                                codigoPostal: d.DirCpo,
                                provincia: d.DirPro,
                                telefono: d.DirTel,
                                pais: d.DirPai,
                                defecto: d.DirDef
                            }
                        })
                        peticionOk = true
                    }
                }
                else {
                    // Crear Usuario. //
                    objUser = {
                        usuario: values.CliUsu,
                        email: values.CliEma,
                        password: values.CliPas,
                        name: values.CliNom,
                        nif: values.CliNif.toUpperCase(),
                        CliTel: values.CliTel,
                        direc: values.direc,
                        consentimiento1: chkValues[0].checked,
                        consentimiento2: chkValues[1].checked,
                        consentimiento3: chkValues[2].checked,
                        // Solicitudes. //
                        CliMer: values.CliMer,
                        idioma: valuesGlobales.idioma
                    }

                    // Crear el Usuario. //
                    data = await crearCliente(objUser)
                    // Procesar respuesta. //
                    if (data === undefined) {
                        const msgError = { msg: t('El usuario o el password no son correctos') }
                        throw msgError
                    } else if (data.error) {
                        //console.log("Error a revisar: ", data)
                        if (data.err.code === "ER_DUP_ENTRY") {
                            const msgError = { msg: "Usuario ya existente" }
                            throw msgError
                        }
                        throw t(data.error)
                    }
                    else {
                        //console.log("Autenticar con data!!!", data)
                        /*                        
                            auth.authenticate(data, () => {
                                // Actualizar valuesGlobales (Sin asignar al gancho)
                                valuesGlobales.ivaUsuario = 1
                                valuesGlobales.nombreUsuario = objUser.name
                                valuesGlobales.emailUsuario = objUser.email
                                valuesGlobales.nifUsuario = objUser.nif
                                valuesGlobales.dirUsuario = objUser.direc
                                //valuesGlobales.mercadosUsuario = objUser.tarifas
                            })
                        */
                        peticionOk = true
                    }
                }
                if (peticionOk) {
                    data = await enviaEmailPeticion(values.CliEma, values.cooEma, values.CliMer, values.nombreMercado)
                    // Procesar respuesta. //
                    if (data === undefined) {
                        const msgError = { msg: t('Error en envío eMail') }
                        throw msgError
                    } else if (data.error) {
                        //console.log("Error a revisar: ", data)
                        throw t(data.error)
                    }
                    else {
                        //console.log("Error a revisar: ", data)
                        console.log("Envio email OK.")
                    }
                }
            }
            catch (err) {
                if (err.msg === "Usuario ya existente") {
                    errors.usuario = t("Usuario ya existente")
                }
                if (err.msg !== undefined) {
                    errors.usuario = err.msg
                }
                else {
                    errors.usuario = err
                }
                console.log("Hay error ", err)
            }
            // Actualizar errores.
            setErrors(errors)
            // Necesario actualizar algún gancho para que pinte en pantalla si hay error.
            setValues({ ...values, CliNom: values.CliNom });
            // Si es solicitud de acceso a un mercado. //
            if (usuIden && values.CliMer !== '' && errors.usuario === '') {
                // Dar un mensaje en pantalla y volver al botón del mercado. //
                alert(t("Mercados.Solicitud de alta") + " Ok.")
                return true
            }
        } else {
            alert(t("Revise los errores"))
        }
    }

    const textoDirec = (dato, index) => {
        let texto = ''
        switch (dato) {
            case "nombre":
                texto = t('Introduzca el Nombre')
                break
            case "direccion":
                texto = t('Introduzca el Domicilio')
                break
            case "codigoPostal":
                texto = t('Introduzca el Código Postal')
                break
            case "poblacion":
                texto = t('Introduzca la Población')
                break
            case "provincia":
                texto = t('Introduzca la Provincia')
                break
            case "telefono":
                texto = t('Introduzca su Nº de Teléfono')
                break
            default:
                texto = "Campo no encontrado"
                break
        }
        if (errors.direc === undefined ||
            errors.direc[index][dato] === undefined || errors.direc[index][dato] === '') {
            return <Typography className={classes.mensajeHelper}>
                {texto}
            </Typography>
        } else {
            return <Typography className={classes.mensajeError}>
                {errors.direc[index][dato]}
            </Typography>
        }
    }


    const MiCheckboxGroup = ({ values, label, onChange }) => {
        return (
            <FormControl component="fieldset" style={{/*marginLeft:20,*/ marginTop: 10 }}>
                <FormLabel component="legend" /*style={{fontSize:15}}*/>
                    {label}
                </FormLabel>
                <FormGroup style={{ marginTop: 10 }}>
                    {values.map((value, index) => (
                        <FormControlLabel
                            key={index}
                            control={<Checkbox
                                //className={classes.root}
                                style={{
                                    padding: '2px 2px 2px 2px',
                                    marginLeft: 10,
                                    marginRight: 10,
                                }}
                                id={"Chk" + index}
                                disabled={index < 2}
                                checkedIcon={<span className={clsx(classes.iconChkbox, classes.checkedIconChkBox)} />}
                                icon={<span className={classes.iconChkbox} />}
                                checked={value.checked}
                                onChange={onChange(index)}
                            />}
                            label={
                                <Typography style={{ fontSize: 15, /*color:'red'*/ }}>
                                    {value.label}
                                </Typography>
                            }
                        />
                    ))}
                </FormGroup>
            </FormControl>
        )
    };

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Container /*maxWidth="md"*/ className={classes.registro}>
                <Grid container className={classes.gridContainer}>
                    <Grid item className={classes.itemText}>
                        <Typography style={{ fontSize: '32px', color: '#00233D', fontWeight: 600, marginTop: 10 }}>
                            {values.nombreMercado === '' ? t('Usuario') : t('Solicitud de Alta en Mercado') + ': ' + values.nombreMercado}
                        </Typography>
                    </Grid>
                    {!usuIden &&
                        <Grid item className={classes.itemText}>
                            <Typography style={{ fontSize: '16px', color: '#808080' }}>
                                {t("Si ya estas dado de alta en SAREKO")}
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <Paper className={classes.paper}>
                    <Grid container className={classes.gridContainer}>
                        <Grid item className={classes.itemText}>
                            <TextField
                                id="Usuario"
                                type="text"
                                required
                                disabled={usuIden} // No dejar modificar si está identificado
                                label={t("Usuario")}
                                value={values.CliUsu}
                                className={classes.textField}
                                fullWidth
                                onChange={handleChange('CliUsu')}
                            />
                            {(() => {
                                if (errors.usuario === undefined || errors.usuario === '') {
                                    return (
                                        <Typography className={classes.mensajeHelper}>
                                            {t('Introduzca su Usuario')}
                                        </Typography>
                                    )
                                } else {
                                    return <Typography className={classes.mensajeError}> {errors.usuario}</Typography>
                                }
                            })()}
                        </Grid>
                        <Grid item className={classes.itemText}>
                            <FormControl /*fullwidth*/ className={classes.textField}>
                                <InputLabel required htmlFor={"password"} /* corregir warning de Label */>
                                    {t("Password")}
                                </InputLabel>
                                <Input
                                    //className={classes.textField}
                                    style={{
                                        marginBottom: 12,
                                        //marginRight: 25,
                                        //width: '40ch'
                                    }}
                                    inputProps={{ id: "password", name: "password" } /* corregir warning de Label */}
                                    //id="edita-password"
                                    type={values.verClave ? 'text' : "Password"}
                                    disabled={usuIden && values.CliMer !== ''}
                                    value={values.CliPas}
                                    onChange={handleChange('CliPas')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickVerClave}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {values.verClave ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {(() => {
                                    if (errors.password === undefined || errors.password === '') {
                                        return (
                                            <Typography className={classes.mensajeHelper}>
                                                {t('Introduzca su Password')}
                                            </Typography>)

                                    } else {
                                        return <Typography className={classes.mensajeError}> {errors.password}</Typography>
                                    }
                                })()}
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.itemText}>
                            <TextField
                                id="CliEma"
                                type="email"
                                required
                                disabled={usuIden && values.CliMer !== ''}
                                label={t("Email")}
                                value={values.CliEma}
                                className={classes.textField}
                                fullWidth
                                onChange={handleChange('CliEma')}
                            />
                            {(() => {
                                if (errors.email === undefined || errors.email === '') {
                                    return (
                                        <Typography className={classes.mensajeHelper}>
                                            {t('Introduzca su dirección de Email')}
                                        </Typography>
                                    )
                                } else {
                                    return <Typography className={classes.mensajeError}> {errors.email}</Typography>
                                }
                            })()}
                        </Grid>
                        <Grid item className={classes.itemText}>
                            <TextField
                                id="NIF"
                                type="text"
                                disabled={usuIden && values.CliMer !== ''}
                                required
                                label={t("DNI o NIF")}
                                value={values.CliNif}
                                className={classes.textField}
                                fullWidth
                                onChange={handleChange('CliNif')}
                            />
                            {(() => {
                                if (errors.nif === undefined || errors.nif === '') {
                                    return (
                                        <Typography className={classes.mensajeHelper}>
                                            {t('Introduzca su DNI o NIF')}
                                        </Typography>
                                    )
                                } else {
                                    return <Typography className={classes.mensajeError}> {errors.nif}</Typography>
                                }
                            })()}
                        </Grid>
                        <Grid item className={classes.itemText}>
                            <TextField
                                id="Nombre"
                                type="text"
                                disabled={usuIden && values.CliMer !== ''}
                                required
                                label={t("Nombre y Apellidos o Razón Social")}
                                value={values.CliNom}
                                className={classes.textField}
                                fullWidth
                                onChange={handleChange('CliNom')}
                            />
                            {(() => {
                                if (errors.name === undefined || errors.name === '') {
                                    return (
                                        <Typography className={classes.mensajeHelper}>
                                            {t('Introduzca Nombre y Apellidos o Razón Social')}
                                        </Typography>
                                    )
                                } else {
                                    return <Typography className={classes.mensajeError}> {errors.name}</Typography>
                                }
                            })()}
                        </Grid>
                        <Grid item className={classes.itemText}>
                            <TextField
                                id="Telefono"
                                type="text"
                                disabled={usuIden && values.CliMer !== ''}
                                required
                                label={t("Nº Teléfono")}
                                value={values.CliTel}
                                className={classes.textField}
                                fullWidth
                                onChange={handleChange('CliTel')}
                            />
                            {(() => {
                                if (errors.telefono === undefined || errors.telefono === '') {
                                    return (
                                        <Typography className={classes.mensajeHelper}>
                                            {t('Introduzca su Nº de Teléfono')}
                                        </Typography>
                                    )
                                } else {
                                    return <Typography className={classes.mensajeError}> {errors.telefono}</Typography>
                                }
                            })()}
                        </Grid>
                    </Grid>
                    <br />
                </Paper>
                <br />
                <Paper className={classes.paper1}>
                    <Grid key={"fpg_"} container className={classes.gridContainer}>
                        {values.CliMer !== '' ?
                            <Grid item className={classes.itemText}>
                                <Typography
                                    style={{
                                        fontSize: '18px',
                                        color: '#00233D',
                                        fontWeight: 600
                                    }}>
                                    {t("Carro.FormaDePago")}
                                </Typography>
                                {/*
                                <RadioGroup value={values.formaDePago}
                                    aria-label="tipoPago"
                                    name="tipoPago"
                                    onChange={changeFormaDePago}
                                >
                                    {values.formasDePago.map((l) => (
                                        <FormControlLabel
                                            key={'Fp' + l.tipo}
                                            classes={{
                                                label: classes.checkboxLabel
                                            }}
                                            value={l.tipo}
                                            control={<StyledRadio />}
                                            label={valuesGlobales.idioma === "Es" ? l.textoEs : l.textoEu}
                                        />
                                    ))}
                                </RadioGroup>
                                */}
                                {values.formasDePago.map((l, i) => (
                                    <Typography
                                        id={"fp" + i}
                                        key={"fp" + i}
                                        style={{
                                            fontSize: '14px',
                                            //color: '#00233D',
                                            //fontWeight: 600,
                                            marginTop: 2,
                                        }}>
                                        {valuesGlobales.idioma === "Es" ? l.textoEs : l.textoEu}
                                    </Typography>
                                ))}
                            </Grid>
                            :
                            <Fragment />
                        }
                    </Grid>
                </Paper>

                {values.direc.map((dir, i) => (
                    <Grid key={"dir_" + i}
                        /*container className={classes.gridContainer}*/>
                        <Paper className={classes.paper}>
                            {i === 0 ?
                                <Typography style={{ fontSize: '22px', color: '#00233D', fontWeight: 600, marginTop: 10, /*marginBottom: 10*/ }}>
                                    {t('Direccion de Facturacion y Envio')}
                                </Typography>
                                :
                                <Typography style={{ fontSize: '22px', color: '#00233D', fontWeight: 600, marginTop: 10, /*marginBottom: 10*/ }}>
                                    {t('Direccion de Solo de Envio')}
                                </Typography>
                            }
                            <Grid container className={classes.gridContainer}>
                                <Grid item className={classes.itemText}>
                                    <TextField
                                        id="DirNom"
                                        type="text"
                                        disabled={usuIden && values.CliMer !== ''}
                                        required
                                        label={t("Nombre")}
                                        value={dir.DirNom}
                                        className={classes.textField}
                                        fullWidth
                                        onChange={handleChange('DirNom', i)}
                                    />
                                    {textoDirec('nombre', i)}
                                </Grid>
                                <Grid item className={classes.itemText}>
                                    <TextField
                                        id="Calle"
                                        type="text"
                                        disabled={usuIden && values.CliMer !== ''}
                                        required
                                        label={t("Domicilio")}
                                        value={dir.DirCal}
                                        className={classes.textField}
                                        fullWidth
                                        onChange={handleChange('DirCal', i)}
                                    />
                                    {textoDirec('direccion', i)}
                                </Grid>
                                <Grid item className={classes.itemDir}>
                                    <TextField
                                        id="CPostal"
                                        type="text"
                                        disabled={usuIden && values.CliMer !== ''}
                                        required
                                        label={t("Cº Postal")}
                                        value={dir.DirCpo}
                                        className={classes.textDir}
                                        fullWidth
                                        onChange={handleChange('DirCpo', i)}
                                    />
                                    {textoDirec('codigoPostal', i)}
                                </Grid>
                                <Grid item className={classes.itemDir}>
                                    <TextField
                                        id="Poblacion"
                                        type="text"
                                        disabled={true}
                                        required
                                        label={t("Población")}
                                        value={dir.DirPob}
                                        className={classes.textDir}
                                        fullWidth
                                    //onChange={handleChange('DirPob', i)}
                                    />
                                    {textoDirec('poblacion', i)}
                                </Grid>
                                <Grid item className={classes.itemDir}>
                                    <TextField
                                        id="Provincia"
                                        type="text"
                                        disabled={usuIden && values.CliMer !== ''}
                                        required
                                        label={t("Provincia")}
                                        value={dir.DirPro}
                                        className={classes.textDir}
                                        fullWidth
                                        onChange={handleChange('DirPro', i)}
                                    />
                                    {textoDirec('provincia', i)}
                                </Grid>

                                <Grid item className={classes.itemDir}>
                                    <TextField
                                        id="DirTel"
                                        type="text"
                                        disabled={usuIden && values.CliMer !== ''}
                                        required
                                        label={t("Nº Teléfono")}
                                        value={dir.DirTel}
                                        className={classes.textDir}
                                        fullWidth
                                        onChange={handleChange('DirTel', i)}
                                    />
                                    {textoDirec('telefono', i)}
                                </Grid>
                            </Grid>
                            <br />
                        </Paper>
                        <br />
                    </Grid>
                ))}
                <Grid container className={classes.gridContainer}>
                    <Grid item /*className={classes.checkGroup}*/>
                        <MiCheckboxGroup
                            label={t("Consentimientos")}
                            values={chkValues}
                            onChange={onChangeChk}
                        />
                    </Grid>
                    <Grid item className={classes.itemBoton}>
                        <BotonValidacion
                            texto={t('Aceptar')}
                            salto={valuesGlobales.paginaOrigen}
                            validacion={handleSubmit}
                            euskarriak={euskarriak}
                        />
                    </Grid>
                </Grid>
                {/*<Box container display="flex" justifyContent="flex-end" m={1} p={1}>
                </Box>*/}
            </Container>
            {(() => {
                if (values.saltoIde) {
                    return <Redirect to={'/Identificarse'} />
                }
            })()}
        </MuiThemeProvider >
    );
}

export default withTranslation()(Usuarios)