import React, { useState } from "react";
import { createStore, useStore } from "react-hookstore";
import { withTranslation } from "react-i18next";
import { Redirect, useLocation, useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { signinCli } from "./../../apis/api-auth.js";
import auth from "./../../apis/auth-helper.js";
import { listaNotificacionesUsuario } from "../../apis/api-notificacion.js";

import ThemeGeroa from "../../components/ThemeGeroa.js";
import Boton from "../../components/botones/Boton";
import BotonValidacion from "../../components/botones/BotonValidacion";

import config from "./../../config/config";
import { BuscaIndice } from "../../config/funcionesPropias.js";

const useStyles = makeStyles((theme) => ({
  registro: {
    marginTop: "0px",
    minHeight: "65vh",
  },
  mensajeHelper: {
    color: "rgba(0, 0, 0, 0.54)",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "-6px",
    minHeight: "1em",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: "1em",
    letterSpacing: "0.03333em",
  },
  mensajeError: {
    color: "#ff3878",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "-6px",
    minHeight: "1em",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: "1em",
    letterSpacing: "0.03333em",
  },
  textField: {
    marginBottom: 12,
  },
  conSubrayado: {
    textDecoration: "underline blue",
    color: "blue",
    cursor: "pointer",
  },
  nounderline: {
    color: "blue",
    cursor: "pointer",
  },
}));

createStore("Datos");

const Identificarse = ({ t }) => {
  const classes = useStyles();
  const [valuesGlobales, setValuesGlobales] = useStore("DatosGlobales");

  const [salto, setSalto] = useState("");
  const [values, setValues] = useStore("Datos");
  const [errors, setErrors] = useState({});
  const location = useLocation(); // Para obtener la URL de origen
  const history = useHistory(); // Para redirigir después del login

  async function validaAlta(event) {
    console.log("Actualizando values globales a 021")
    valuesGlobales.usuarioAltaMercado = { merCod: "021", merNom: "EUSKARRIAK/ SOPORTES"}
    return true
}


  const params = new URLSearchParams(location.search);
  const from = params.get("from") || "/"; // Si no hay origen, redirigir a "/"

  console.log("URL de origen:", from);

  if (values.usuario === undefined) {
    values.usuario = "";
  }
  if (values.password === undefined) {
    values.password = "";
  }

  const handleChange = (name) => (event) => {
    let texto = event.target.value.split("'").join("");
    texto = texto.split('"').join("");
    setValues({ ...values, [name]: texto });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = (values) => {
    if (values.usuario.length === 0) {
      errors.usuario = t("El Usuario es obligatorio");
    } else {
      errors.usuario = "";
    }
    if (values.password.length === 0) {
      errors.password = t("El password es obligatorio");
    } else if (values.password.length < 4) {
      errors.password = t("Este password parece demasiado corto");
    } else {
      errors.password = "";
    }
    return errors;
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setErrors(validate(values));
    setValues({ ...values, usuario: values.usuario, password: values.password });
    if (errors.usuario === "" && errors.password === "") {
      try {
        const data = await signinCli({ u: values.usuario, p: values.password });
        if (data === undefined || data === "Usuario o Password incorrectos") {
          throw t("El Usuario o el password no son correctos");
        } else {
          auth.authenticate(data, () => {
            let iva = 0;
            if (data.user.ivaUsuario) {
              iva = 1;
              if (data.user.recargoUsuario) {
                iva = 2;
              }
            }

            setValuesGlobales({
              ...valuesGlobales,
              ivaUsuario: iva,
              usuario: data.user.usuario,
              nombreUsuario: data.user.name,
              emailUsuario: data.user.email,
              codigoUsuario: data.user.codigo,
              nifUsuario: data.user.nif,
              dirUsuario: data.user.direcciones,
              mercadosUsuario: data.user.tarifas,
              recibo: data.user.recibo,
            });

            if (config.notificaciones) {
              listaNotificacionesUsuario(
                { usuarioEmail: data.user.email },
                { t: "" }
              ).then((dataN) => {
                if (!dataN.error) {
                  setValuesGlobales({
                    ...valuesGlobales,
                    notificaciones: dataN,
                  });
                }
              });
            }
            let razon = ""
            // Redirigir al usuario a la URL de origen o a la página principal
            let redirectTo = location.state?.from?.pathname || "/";
            if (from === "/CarroCompra") {
              redirectTo = "/CarroCompra"
              razon = "euskarriak"
            }
            console.log("Redirect to: ", redirectTo)
            history.push(redirectTo, { from: location.pathname, reason: razon });
          });
          errors.password = "";
        }
      } catch (err) {
        errors.password = err;
      }
      setErrors(errors);
      setValues({ ...values, usuario: values.usuario, password: values.password });
    }
    return errors.usuario === "" && errors.password === "" ? true : false;
  }

  return (
    <MuiThemeProvider theme={ThemeGeroa}>
      <Container maxWidth="md" className={classes.registro}>
        <Typography
          style={{
            fontSize: "32px",
            color: "#00233D",
            fontWeight: 600,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          {t("Identificación")}
        </Typography>
        <Grid item style={{ maxWidth: 530 }}>
          <TextField
            className={classes.textField}
            id="usuario"
            type="text"
            required
            label={t("Usuario")}
            value={values.usuario}
            fullWidth
            onChange={handleChange("usuario")}
          />
          {errors.usuario && (
            <Typography className={classes.mensajeError}>
              {errors.usuario}
            </Typography>
          )}
          <FormControl fullWidth>
            <InputLabel required htmlFor={"password"}>
              {t("Password")}
            </InputLabel>
            <Input
              className={classes.textField}
              inputProps={{ id: "password", name: "password" }}
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password && (
              <Typography className={classes.mensajeError}>
                {errors.password}
              </Typography>
            )}
          </FormControl>
          <Grid item>
            <BotonValidacion
              texto={t("Aceptar")}
              validacion={handleSubmit}
            />
            {from === "/CarroCompra" ? (
              <BotonValidacion
                texto={t("Mercados.Solicitud de alta")}
                salto="/Usuarios"
                validacion={validaAlta}
                euskarriak={true}
                style={{ marginLeft: '16px' }}
              />
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </MuiThemeProvider>
  );
};

export default withTranslation()(Identificarse);